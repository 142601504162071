import * as React from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { media } from 'styleguide/helpers/media';
import AimoLogoIcon from 'styleguide/components/Logos/Logo/AimoLogoIcon';
import ContentTag from 'styleguide/components/Tags/ContentTag/ContentTag';
import { TEXT_WIDTH, TEXT_WIDTH_NARROW } from 'common/components/Widgets/constants';
import { FramedHeaderVariants } from './types';
import DotSeparator from 'styleguide/components/DotSeparator/DotSeparator';
import { useSelector } from 'react-redux';
import { State } from '../../reducers';
import { WithLeadText } from '../../types/leadText';
import { WithContentFamily } from '../../types/contentFamily';

interface HeaderProps {
	showContentTag?: boolean;
	variant?: FramedHeaderVariants;
	hasContentSubNav: boolean;
}

const Header = styled.header<HeaderProps>`
	background: ${props => props.theme.colors.white};
	padding: ${({ theme }) => theme.grid.gutterInPx()};
	text-align: center;
	position: relative;

	${props => {
		if (props.variant === 'professional') {
			return css`
				margin: -65px auto 0;
				width: calc(100% - ${({ theme }) => theme.grid.gutterInPx(2)});

				${media.tablet`
          margin: -65px auto 20px;  
        `};

				&:before {
					content: '';
					background: white;
					width: 88px;
					height: 88px;
					border-radius: 50%;
					display: block;
					position: absolute;
					top: -35px;
					left: 50%;
					transform: translateX(-50%);

					${media.tablet`
						width: 140px;
						height: 140px;
						top: -53px;
					`};
				}
			`;
		}

		if (props.variant === 'brand' || props.variant === 'imageheader') {
			return css`
				margin: 0;
			`;
		}

		return css`
			margin: ${props.hasContentSubNav ? props.theme.grid.gutterInPx(-3) : props.theme.grid.gutterInPx(-7)} 0 20px;
			width: 100%;
		`;
	}};

	${media.tablet<HeaderProps>`
		${props => {
			if (props.variant === 'brand') {
				return css`
					padding: 0 ${({ theme }) => theme.grid.gutterInPx(4)};
				`;
			}
			if (props.variant === 'imageheader') {
				return css`
					padding: 0 ${({ theme }) => theme.grid.gutterInPx(4)};
					margin: ${({ theme }) => theme.grid.gutterInPx(1)} auto 0;
					width: ${({ theme }) => TEXT_WIDTH_NARROW + theme.grid.gutter * 4}px;
				`;
			}

			return css`
				padding: ${({ theme }) => theme.grid.gutterInPx(2)};
				margin: ${({ theme }) => theme.grid.gutterInPx(-8)} auto 0;
				width: ${({ theme }) => TEXT_WIDTH_NARROW + theme.grid.gutter * 4}px;
			`;
		}};
  `};

	${media.desktop<HeaderProps>`
		${({ variant, theme }) => {
			if (variant === 'brand') {
				return css`
					padding: ${theme.grid.gutterInPx(6)} ${theme.grid.gutterInPx(6)} 0;
					margin: 0;
				`;
			}

			return null;
		}};
	`};

	${media.desktop1440<HeaderProps>`
		${({ variant, theme }) => {
			if (variant === 'brand') {
				return css`
					padding: ${theme.grid.gutterInPx(11)} ${theme.grid.gutterInPx(12.5)} 0;
					margin: 0;
				`;
			}

			if (variant === 'event') {
				return css`
					width: ${TEXT_WIDTH + theme.grid.gutter * 12}px;
				`;
			}

			return css`
				width: ${TEXT_WIDTH + theme.grid.gutter * 4}px;
			`;
		}};
	`};

	.content-tag {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, calc(-50% - 1px));
	}
`;

interface HeaderContainerProps {
	borderColor: string;
	backgroundColor: string;
	textColor: string;
	variant?: FramedHeaderVariants;
}

const HeaderContainer = styled.div<HeaderContainerProps>`
	position: relative;

	${({ variant, borderColor, backgroundColor, textColor, theme }) => {
		if (variant === 'brand') {
			return null;
		}
		return css`
			border: 1px solid ${borderColor};
			background-color: ${variant === 'finlandiacheese' ? 'transparent' : `${backgroundColor}`};
			color: ${textColor};
			padding: ${variant === 'professional'
				? `${theme.grid.gutterInPx(6)} ${theme.grid.gutterInPx(4)} ${theme.grid.gutterInPx(4)}`
				: variant === 'event'
				? `${theme.grid.gutterInPx(2)} ${theme.grid.gutterInPx(6)} 0`
				: variant === 'finlandiacheese'
				? 0
				: theme.grid.gutterInPx(4)};
		`;
	}};

	${media.desktop<HeaderContainerProps>`
		${({ variant, theme }) => {
			if (variant === 'brand') {
				return null;
			}

			return css`
				padding: ${variant === 'professional'
					? `${theme.grid.gutterInPx(7)} ${theme.grid.gutterInPx(5)} ${theme.grid.gutterInPx(5)}`
					: variant === 'event'
					? `${theme.grid.gutterInPx(3)} ${theme.grid.gutterInPx(7)} 0`
					: variant === 'finlandiacheese'
					? 0
					: theme.grid.gutterInPx(5)};
			`;
		}};
	`};

	${props => {
		if (props.variant === 'professional') {
			return css`
				&:before {
					content: '';
					width: 72px;
					height: 36px;
					background: white;
					border-top-left-radius: 72px;
					border-top-right-radius: 72px;
					border: 1px solid ${props.borderColor};
					border-bottom: 0;
					position: absolute;
					top: -37px;
					left: 50%;
					transform: translateX(-50%);

					${media.tablet`
						width: 108px;
						height: 54px;
						border-top-left-radius: 109px;
						border-top-right-radius: 109px;
						top: -55px;
					`};
				}
			`;
		}
		return null;
	}};

	.dot-separator {
		max-width: ${({ theme }) => theme.grid.gutter * 24 + 6}px;

		${media.tablet`
			max-width: none;
		`};
	}
`;

interface TitleProps {
	textColor: string;
	backgroundColor?: string;
	variant?: FramedHeaderVariants;
}

const Title = styled.h1<TitleProps>`
	${({ theme }) => theme.typography.heading};
	line-height: 1;
	margin: 0 auto ${({ theme }) => theme.grid.gutterInPx(2)};
	color: ${props => props.textColor};

	${({ variant, theme, backgroundColor }) => {
		if (variant === 'brand') {
			return css`
				font-size: 20px;
				padding: 0 ${theme.grid.gutterInPx()};
			`;
		}
		if (variant === 'finlandiacheese') {
			return css`
				background-color: ${backgroundColor};
				text-transform: uppercase;
				padding: ${theme.grid.gutterInPx(3)};
			`;
		}
		return css`
			font-size: 24px;
		`;
	}};

	${media.tablet<TitleProps>`
		${({ variant }) => {
			if (variant === 'brand') {
				return css`
					font-size: 36px;
				`;
			}
			return null;
		}};
	`};

	${media.desktop<TitleProps>`
		${({ variant, theme }) => {
			if (variant === 'brand') {
				return css`
					font-size: 48px;
					max-width: 740px;
					margin: 0 auto ${theme.grid.gutterInPx(7)};
					padding: 0;
				`;
			}
			if (variant === 'finlandiacheese') {
				return css`
					font-size: 48px;
				`;
			}
			return css`
				font-size: 36px;
			`;
		}};
  `};
`;

interface LeadTextProps {
	variant?: FramedHeaderVariants;
}

const LeadText = styled.p<LeadTextProps>`
	margin: 0;

	${({ variant }) => {
		if (variant === 'brand') {
			return css`
				font-size: 16px;
				line-height: 1.5;
			`;
		}
		if (variant === 'finlandiacheese') {
			return css`
				font-size: 18px;
			`;
		}
		return css`
			font-size: 21px;
			line-height: 1.25;
		`;
	}};

	${media.desktop<LeadTextProps>`
		font-size: 24px;
		
		${({ variant }) => {
			if (variant === 'brand') {
				return css`
					max-width: 740px;
					margin: 0 auto;
				`;
			}
			if (variant === 'finlandiacheese') {
				return css`
					font-size: 18px;
				`;
			}
			return null;
		}};

  `};
`;

const ProfessionalLogoWrapper = styled.div<{ borderColor: string }>`
	width: 70px;
	border-radius: 50%;
	padding-top: 24px;
	position: absolute;
	top: -35px;
	left: 50%;
	transform: translateX(-50%);

	${media.tablet`
		width: 106px;
		top: -51px;
	`};

	& > img {
		width: 38px;
		height: 38px;
		transform: translate(-2px, -8px);

		${media.tablet`
			width: 60px;
			height: 60px;
			transform: translate(-2px, -4px);
		`};
	}
`;

const BrandLogoWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	padding: ${({ theme }) => theme.grid.gutterInPx(1)} ${({ theme }) => theme.grid.gutterInPx(2)};
	width: 200px;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: ${({ theme }) => theme.grid.gutterInPx(13)};
	margin: ${({ theme }) => theme.grid.gutterInPx(-13)} auto ${({ theme }) => theme.grid.gutterInPx(2)};

	${media.tablet`
	  width: 280px;
    padding: ${({ theme }) => theme.grid.gutterInPx(3)} ${({ theme }) => theme.grid.gutterInPx(3)};
	`};

	${media.desktop`
		width: 376px;
		height: auto;
		min-height: ${({ theme }) => theme.grid.gutterInPx(18)};
		margin: ${({ theme }) => theme.grid.gutterInPx(-18)} auto ${({ theme }) => theme.grid.gutterInPx(4)};
	`};

	${media.desktop1440`
		min-height: ${({ theme }) => theme.grid.gutterInPx(22)};
		margin: ${({ theme }) => theme.grid.gutterInPx(-22)} auto ${({ theme }) => theme.grid.gutterInPx(4)};
	`};

	img,
	svg {
		flex: 0 1 auto;
		max-width: 152px;
		height: auto;

		${media.desktop`
      max-width: 220px;
		`};
	}
`;

interface LeadTextHeaderProps {
	themeColor?: string;
	showContentFamily?: boolean;
	backgroundColor?: string;
	textColor?: string;
	variant?: FramedHeaderVariants;
	className?: string;
	highlightImage?: string;
	highlightIcon?: React.ReactElement<HTMLElement> | null;
	showBorder?: boolean;
}

const FramedHeader: React.FunctionComponent<LeadTextHeaderProps> = ({
	themeColor,
	showContentFamily,
	backgroundColor,
	textColor,
	variant,
	className,
	highlightImage,
	highlightIcon,
	showBorder = true,
}) => {
	const theme = React.useContext(ThemeContext);
	const { titleHtml, contentFamily, leadText, hasContentSubNav } = useReduxState();

	const usedThemeColor =
		themeColor && themeColor !== theme.colors.brandPrimary ? themeColor : theme.colors.framedHeaderTitle;

	const borderColor = theme.colors.framedHeaderBorder || usedThemeColor;

	let titleColor = usedThemeColor;

	variant = variant ?? theme.styledVariations.framedHeaderDefaultVariant;

	if (!backgroundColor) {
		backgroundColor = theme.colors.framedHeaderBackground;
	}

	if (!textColor) {
		textColor = theme.colors.bodyText;
	} else {
		// If there is textColor override, do not use theme color for title neither
		// as usually in this case themecolor is used as backgroundcolor. Brand variant
		// uses themeColor so don't override in that case.
		if (variant !== 'brand') {
			titleColor = textColor;
		}
	}

	const shouldShowContentTag =
		showContentFamily && contentFamily !== '' && variant !== 'professional' && variant !== 'finlandiacheese';

	return (
		<Header
			showContentTag={shouldShowContentTag}
			variant={variant}
			className={className}
			hasContentSubNav={hasContentSubNav || false}>
			{shouldShowContentTag && (
				<ContentTag color={usedThemeColor} className="content-tag" disableMobileSize={true}>
					{contentFamily}
				</ContentTag>
			)}
			<HeaderContainer
				borderColor={variant === 'event' ? 'transparent' : borderColor}
				backgroundColor={backgroundColor}
				textColor={textColor}
				variant={variant}>
				{variant === 'professional' && (
					<ProfessionalLogoWrapper borderColor={borderColor}>
						<AimoLogoIcon noBorder={true} disableScaling={true} logoSize={'80%'} />
					</ProfessionalLogoWrapper>
				)}
				{variant === 'brand' && (
					<BrandLogoWrapper>{highlightIcon ? highlightIcon : <img src={highlightImage} />}</BrandLogoWrapper>
				)}
				<Title
					className="framed-header__title"
					textColor={titleColor || textColor}
					backgroundColor={backgroundColor}
					variant={variant}
					dangerouslySetInnerHTML={{ __html: titleHtml || '' }}
				/>
				{leadText && <LeadText variant={variant}>{leadText}</LeadText>}
				{variant === 'brand' && <DotSeparator color={usedThemeColor} className="dot-separator" />}
			</HeaderContainer>
		</Header>
	);
};

function useReduxState() {
	return useSelector(({ resource, navigation }: State) => {
		const { content, titleHtml } = resource;
		const { contentFamily, leadText } = content as WithLeadText & WithContentFamily;

		return {
			titleHtml,
			contentFamily,
			leadText,
			hasContentSubNav: !!navigation.secondaryNavigation,
		};
	});
}

export default FramedHeader;
