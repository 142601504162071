import * as React from 'react';
import { ContentWrapper } from 'common/components/General';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import styled from 'styled-components';
import { media } from 'styleguide/helpers/media';

interface BasicHeaderProps {
	hasContentSubNav?: boolean;
}

const BasicHeader = styled.header<BasicHeaderProps>`
	width: 100%;
	max-width: 720px;
	margin: ${props => (props.hasContentSubNav ? 20 : 40)}px auto 0;
	padding: 0 10px;

	${media.desktop<BasicHeaderProps>`
		max-width: 600px;
		padding: 0;
		margin-top: ${props => (props.hasContentSubNav ? 80 : 100)}px;
	`};

	${media.desktop1440`
		max-width: 720px;
	`};
`;

const BasicFrontTitle = styled.h1<{ color?: string }>`
	font-family: ${({ theme }) => theme.fonts.primary};
	font-size: 24px;
	font-weight: 600;
	line-height: 26px;
	color: ${({ color, theme }) => color || theme.colors.brandPrimary};
	margin: 0 0 20px;
	text-align: left;
	hyphens: manual;

	${media.desktop`
  	font-size: 36px;
    line-height: 1;
  `};
`;

const BasicFrontSubTitle = styled.p`
	font-size: 18px;
	line-height: 24px;

	${media.desktop`
  	font-size: 24px;
    line-height: 30px;
		margin: 0;
  `};
`;

interface Props {
	titleHtml?: string;
	leadText?: string;
	hasContentSubNav?: boolean;
	themeColor?: string;
}

const Basic: React.FC<Props> = ({ titleHtml = '', leadText, hasContentSubNav, themeColor }) => {
	return (
		<>
			<ContentWrapper>
				<BasicHeader hasContentSubNav={hasContentSubNav}>
					<BasicFrontTitle color={themeColor} dangerouslySetInnerHTML={{ __html: titleHtml }} />
					{leadText && <BasicFrontSubTitle>{leadText}</BasicFrontSubTitle>}
				</BasicHeader>

				<WidgetZone name="Main" isFullWidth={true} />
			</ContentWrapper>
		</>
	);
};

export default Basic;
