import * as React from 'react';
import { NavLinkItemProps } from '../../../components/Navigation/interfaces';
import HighlightedLink from 'styleguide/components/Links/HighlightedLink/HighlightedLink';
import styled from 'styled-components';

const TopicsWrapper = styled.ul`
	margin: 20px 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

const Topic = styled.li`
	margin: 3px;
`;

interface TopicsProps {
	topics?: NavLinkItemProps[];
}

const Topics: React.FC<TopicsProps> = ({ topics }) => {
	if (!topics || topics.length === 0) {
		return null;
	}

	return (
		<TopicsWrapper>
			{topics.map((topic, index) => (
				<Topic key={`topic-${index}`}>
					<HighlightedLink href={topic.url} intense={true}>
						{topic.title}
					</HighlightedLink>
				</Topic>
			))}
		</TopicsWrapper>
	);
};

export default Topics;
