import * as React from 'react';
import { ContentWrapper } from 'common/components/General';
import Hero from 'styleguide/components/HeroBanners/Hero';
import WidgetZone from 'common/components/Widgets/WidgetZone';
import { ContentThemeContext } from 'common/contexts/ContentThemeContext';
import { ResourceState } from 'common/components/Resource/reducers/reducers';
import Topics from './components/Topics';
import FramedHeader from 'common/components/FramedHeader';
import { FramedHeaderVariants } from 'common/components/FramedHeader/types';
import { Icon } from 'utils/import';
import { useHeroMedia } from '../../hooks/useHeroMedia';

type LeadTextResourceState = Pick<ResourceState, 'topics' | 'previewImage'>;

export interface LeadTextProps extends LeadTextResourceState {
	showContentFamily?: boolean;
	backgroundColor?: string;
	textColor?: string;
	variant?: FramedHeaderVariants;
	highlightIcon?: ResourceState['highlightIcon'];
	showBorder?: boolean;
}

const LeadText: React.FC<LeadTextProps> = ({
	showContentFamily = true,
	backgroundColor,
	textColor,
	variant,
	topics,
	previewImage,
	highlightIcon,
	showBorder,
}) => {
	const { heroMedia, heroImageOffsetY } = useHeroMedia('cms-content-default-hero');

	return (
		<>
			<Hero src={heroMedia.mediaSrc} mediaType={heroMedia.mediaType} offsetY={heroImageOffsetY} />

			<ContentWrapper>
				<ContentThemeContext.Consumer>
					{contentTheme => (
						<FramedHeader
							themeColor={contentTheme.color}
							showContentFamily={showContentFamily === true}
							backgroundColor={backgroundColor}
							textColor={textColor}
							variant={variant}
							highlightImage={previewImage}
							highlightIcon={<Icon iconName={highlightIcon} />}
							showBorder={showBorder}
						/>
					)}
				</ContentThemeContext.Consumer>
				<Topics topics={topics} />
				<WidgetZone name="Main" isFullWidth={true} />
			</ContentWrapper>
		</>
	);
};

export default LeadText;
