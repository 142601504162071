import * as React from 'react';
import styled, { ThemeContext } from 'styled-components';
import { generate } from 'shortid';
import { media } from 'styleguide/helpers';

const DotSeparatorWrapper = styled.div`
	height: 6px;
	margin: ${({ theme }) => theme.grid.gutterInPx(4)} auto;

	${media.tablet`
    margin: ${({ theme }) => theme.grid.gutterInPx(6)} auto;
  `};

	${media.desktop`
    margin: ${({ theme }) => theme.grid.gutterInPx(9)} auto;
  `};

	svg {
		display: block;
	}
`;

interface Props {
	color?: string;
}

const DotSeparator: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({ color, ...rest }) => {
	const theme = React.useContext(ThemeContext);

	const id = generate();

	return (
		<DotSeparatorWrapper {...rest}>
			<svg width="100%" height="100%">
				<defs>
					<pattern id={`polka-dots-${id}`} x="0" y="0" width="24" height="24" patternUnits="userSpaceOnUse">
						<circle fill={color ?? theme.colors.brandPrimary} cx="3" cy="3" r="3" />
					</pattern>
				</defs>
				<rect x="0" y="0" width="100%" height="100%" fill={`url(#polka-dots-${id})`} />
			</svg>
		</DotSeparatorWrapper>
	);
};

export default DotSeparator;
